

















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissL5PostLabPart7',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part5_7: null,
      },
      questions: [
        {
          text: 'The object that was found is a piece from a tube that carries cold liquid throughout the rocket. It sits near the heat of the rocket engine and is almost always exposed to the weather. If you had to choose one single most important physical or chemical property of this piece, what would it be? Why this property over any others?',
          inputName: 'part5_7',
        },
      ],
    };
  },
};
